@if ( !loading && hasCompletedOnboardingCheck) {
<header
        class="fixed top-0 left-0 z-50 flex flex-row items-center justify-start w-full px-10 bg-white border-solid shadow h-60 dark:bg-opacity-50 gap-x-10 dark:bg-gray-darkest dark:backdrop-blur dark:shadow-none dark:border-b dark:border-gray-darker lg:px-20"
        @fadeIn>
  @if (!loading) {
  <a class="items-center justify-start flex-none hidden uppercase sm:flex hover:no-underline focus:no-underline text-ferra-800"
     [routerLink]="'/Portal/Dashboard'" aria-label="Home" title="Agent Portal">
    @if (!logo) {
    <img class="hidden h-40 sm:block" src=".\assets\images\fig-logo-tri-gray.svg" alt="Agent Portal">
    }
    @else {
    <img class="w-auto h-auto max-w-128 max-h-40" src="{{logo}}?d={{clockTick}}"
         [alt]="!!userGroup ? userGroup.Name : 'Portal'">
    }
  </a>
  }
  <span class="flex-none h-40 mx-0 xl:mx-10 divider is-vertical"></span>
  <!-- Main nav -->
  <app-main-nav-v2 class="mr-auto"></app-main-nav-v2>
  <span class="flex-none h-40 mx-0 xl:mx-10 divider is-vertical"></span>
  <!-- Searchbox -->
  <app-global-search-v2></app-global-search-v2>
  <span class="flex-none h-40 mx-0 xl:mx-10 divider is-vertical"></span>
  <!-- Explore button -->
  <a *showIfOne="explorePermissions" routerLink="Explore" mat-button
     amplitudeEvent="Explore Header Button"
     class="hidden mx-10 text-white shadow-sm button is-lg bg-gradient-to-br from-blue to-blue-950 shadow-blue-dark hover:shadow-lg hover:shadow-blue lg:block">Explore FIG</a>
  <!-- Quick links -->
  <ng-container>
    <button mat-button type="button"
            class="flex-none button is-primary is-lg is-inverted"
            amplitudeEvent="Open Quick Links"
            [matMenuTriggerFor]="quickLinksMenu">
      <span class="mr-5">Quick Links</span>
      <i class="fig-18px fig-triangle-down-filled"></i>
    </button>
    <mat-menu #quickLinksMenu="matMenu" xPosition="before">
      @if (!environment.production) {
      <button
              mat-menu-item
              type="button"
              class="button is-lg"
              routerLink="AngularSandbox">
        Sandbox (Dev)
      </button>
      }
      <a *showIfOne="explorePermissions"
         routerLink="Explore" mat-menu-item
         amplitudeEvent="Explore Header Button"
         class="button is-lg lg:hidden">Explore FIG</a>
      <button *showIfOne="'IpipelineEApps:Access|FireLight:Access'"
              mat-menu-item
              type="button"
              class="button is-lg"
              (click)="openStartEAppModal()"
              amplitudeEvent="Quick Links - Start E-Application">
        Start E-Application
      </button>
      <button mat-menu-item
              [mat-menu-trigger-for]="continueAppMenu"
              type="button"
              class="button is-lg">
        Continue E-Application
      </button>
      <button *showIfOne="'SubmitPaperApp:View'"
              mat-menu-item
              type="button"
              class="button is-lg"
              (click)="openPaperApplicationModal()"
              amplitudeEvent="Quick Links - Upload Paper Application">
        <span class="">Upload Paper Application</span>
      </button>
    </mat-menu>
    <mat-menu #continueAppMenu="matMenu">
      <!-- WR-3795 - Hide for iComply user groups -->
      @if(canSeeFirelightSso) {
      <button *showIfOne="'FireLight:Access'"
              mat-menu-item
              type="button"
              class="flex items-center button is-lg"
              amplitudeEvent="Quick Links - Continue E-App - Firelight"
              (click)="activateFirelightSso()"
              aria-label="iGo e-Applications">
        <span class="flex items-center justify-start w-full">
          <img class="w-24 h-24" src="/v1/Images/Icons/firelight.svg" aria-label="Firelight Image" alt="Firelight Image">
          <span class="my-auto ml-10">Via Firelight</span>
        </span>
      </button>
      }
      <button *showIfOne="'IpipelineEApps:Access'"
              mat-menu-item
              type="button"
              class="flex items-center button is-lg"
              amplitudeEvent="Quick Links - Continue E-App - IGO"
              (click)="activateIpipelineSso()"
              aria-label="iGo e-Applications">
        <span class="flex items-center justify-start w-full">
          <img class="w-24 h-24" src="/v1/Images/Icons/ipipeline.svg" aria-label="iGo Image" alt="iGo Image">
          <span class="my-auto ml-10">Via iPipeline</span>
        </span>
      </button>
      <button mat-menu-item
              type="button"
              class="flex items-center button is-lg"
              aria-label="Aspida e-Applications"
              amplitudeEvent="Quick Links - Continue E-app - Aspida"
              (click)="activateAspidaSso()"
              aria-label="Aspida e-Applications">
        <span class="flex items-center justify-start w-full">
          <img class="w-24 h-24" src="./assets/images/icons/aspida-icon-color.svg" aria-label="Aspida Image" alt="Aspida Image">
          <span class="my-auto ml-10">Via Aspida</span>
        </span>
      </button>
    </mat-menu>
  </ng-container>
  <span class="flex-none h-40 mx-0 xl:mx-10 divider is-vertical"></span>
  <!-- User sidenav toggle -->
  <button mat-button type="button" class="flex-none px-0 my-10 rounded-full min-w-42 button is-lg is-inverted lg:px-10 dark:text-gray-light lg:min-w-128 w-42 lg:w-auto lg:rounded"
          [matTooltip]="userHasOpenRenewals ? 'You have pending enrollment renewals' : ''"
          (click)="toggleUserSidenav()"
          appCyData="toggleSidenav">
    <span class="flex flex-row items-center justify-center">
      <span class="flex-col hidden leading-tight text-right lg:flex">
        <span class="text-sm font-bold">{{user && user.FirstName ? user.FirstName + ' ' + user.LastName
          :
          'User'}}</span>
        @if (onBehalfOfUser) {
        <span class="text-xs text-gray">Viewing As {{onBehalfOfUser?.FirstName + ' ' + onBehalfOfUser?.LastName }}</span>
        }
      </span>
      <span class="relative flex flex-row items-center justify-center ml-0 rounded-full lg:ml-10 bg-gray-lightest dark:bg-gray-darker w-36 h-36">
        <span class="text-sm font-bold uppercase text-blue-dark dark:text-blue-light">
          <span>
            {{user && user.FirstName ? user.FirstName[0] + user.LastName[0] : 'US'}}
          </span>
          @if (userHasOpenRenewals) {
          <span class="absolute top-0 right-0 w-10 h-10 -mt-5 -mr-5 rounded-full bg-red animate-pulse"></span>
          }
        </span>
      </span>
    </span>
  </button>
</header>
}
@if (!loading && hasCompletedOnboardingCheck) {
<mat-sidenav-container class="w-full mt-60 dark:bg-gray-darkest bg-gray-lightest dark:text-gray-lighter" autosize>
  <!-- User sidenav -->
  <mat-sidenav class="w-full xs:w-1/2 sm:w-320 dark:border-gray-darker" position="end" #userSidenav
               [mode]="userSidenavConfig.Mode" [opened]="userSidenavConfig.IsOpened">
    <section class="flex flex-col p-10 border-b border-solid border-gray-lighter xs:hidden">
      <ng-container>
        <button mat-button type="button" class="m-10 button is-primary" [matMenuTriggerFor]="applicationMenuMobile"
                *showIfOne="'SubmitPaperApp:View|IpipelineEApps:Access|Firelight:Access'">
          <span class="flex items-center justify-center">
            <span class="ml-5">Start Application</span>
            <span class="mi-18 material-icons">arrow_drop_down</span>
          </span>
        </button>
        <mat-menu #applicationMenuMobile="matMenu">
          <button mat-menu-item type="button" class="flex items-center justify-start button is-lg is-inverted" aria-label="Firelight e-Applications"
                  [amplitudeEvent]="'Header Eapp Menu: Firelight'" (click)="activateFirelightSso()"
                  aria-label="Firelight e-Applications">
            <img class="w-24 h-24" src="/v1/Images/Icons/firelight.svg" aria-label="iGo Image" alt="iGo image">
            <span class="my-auto ml-10">E-App via Firelight</span>
          </button>
          <button mat-menu-item type="button" class="flex items-center justify-start button is-lg is-inverted" aria-label="iGo e-Applications"
                  [amplitudeEvent]="'Header Eapp Menu: IGo'" (click)="activateIpipelineSso()"
                  aria-label="iGo e-Applications">
            <img class="w-24 h-24" src="/v1/Images/Icons/ipipeline.svg" aria-label="iPipeline Image" alt="iPipeline Image">
            <span class="my-auto ml-10">E-App via iPipeline</span>
          </button>
          <button mat-menu-item type="button" class="flex items-center justify-center button is-lg is-inverted"
                  [amplitudeEvent]="'Header Eapp Menu: Paper Application'" (click)="openPaperApplicationModal();">
            <span class="mi-18 material-icons">note_alt</span>
            <span class="my-auto ml-10">Paper Application</span>
          </button>
        </mat-menu>
      </ng-container>
    </section>
    <section class="flex flex-col h-full">
      @if (onBehalfOfUser || delegates.length) {
      <section class="flex flex-col flex-none p-10 border-b border-solid border-gray-lighter">
        <div class="flex flex-col m-10 gap-y-10">
          <label for="activeUser" class="text-sm text-gray">
            Viewing As:
          </label>
          <mat-select class="select" name="activeUser" [(ngModel)]="selectedUserId" (selectionChange)="selectActiveUser(selectedUserId)">
            <mat-option [value]="1">
              <span>Myself&nbsp;({{user ? user.FirstName + ' ' + user.LastName : 'User Name'}})</span>
            </mat-option>
            @if (delegates.length) {
            @for (d of delegates; track d) {
            <mat-option [value]="d.Id">
              <span>{{d.OnBehalfOfUser.FirstName + ' ' + d.OnBehalfOfUser.LastName}}</span>
            </mat-option>
            }
            }
            @else if (!delegates.length && !delegateId && onBehalfOfUser) {
            <mat-option [value]="onBehalfOfUser.Id">
              <span>{{onBehalfOfUser.FirstName + ' ' + onBehalfOfUser.LastName}}</span>
            </mat-option>
            }
          </mat-select>
        </div>
      </section>
      }
      <section class="flex flex-col flex-none p-10 border-b border-solid border-gray-lighter"
               *showIfOne="'AccessMarketingPoints:View'">
        <div class="flex flex-col m-10 gap-y-10">
          <div class="flex items-end justify-between mb-10">
            <span class="block text-sm label text-gray">
              Marketing Dollars:
            </span>
            @if (userMarketingDollars) {
            <h2 class="text-lg font-bold"
                [ngClass]="{'text-orange' : userMarketingDollars.Entry.MarketingDollarsTotal < 0}"
                appCyData="marketingDollars">{{userMarketingDollars?.Entry?.MarketingDollarsTotal | currency}}
            </h2>
            }
          </div>
          <button mat-button type="button" class="button is-primary is-inverted" [amplitudeEvent]="'Menu: Submit Marketing Expenses'"
                  *showIfOne="'PointAdjustments:View'" (click)="openRedeemMarketingDollars()"
                  appCyData="submitMarketingExpenses">
            <span class="flex items-center justify-start w-full">
              <span class="mr-10 mi-18 material-icons">monetization_on</span>
              <span>Submit Marketing Expenses</span>
            </span>
          </button>
          <button mat-button type="button" class="button is-primary is-inverted" [amplitudeEvent]="'Menu: View Expense History'"
                  (click)="openMarketingExpenseHistoryModal()">
            <span class="flex items-center justify-start w-full">
              <span class="mr-10 mi-18 material-icons">history</span>
              <span>View Expense History</span>
            </span>
          </button>
        </div>
      </section>
      @if (hasCPCPermission && userCPCs) {
      <section class="flex flex-col flex-none p-10 border-b border-solid border-gray-lighter">
        <div class="flex flex-col m-10 gap-y-20">
          <div class="flex items-end justify-between">
            <span class="block text-sm label text-gray">
              Total CPCs:
            </span>
            <h2 class="text-lg font-bold" [ngClass]="{'text-orange' : userCPCs.CPCs < 0}"> {{userCPCs?.CPCs | number}}
            </h2>
          </div>
          <div class="flex flex-row" layout>
            <input class="flex-auto mr-10 input" [matDatepicker]="startDate" [(ngModel)]="cpcStartDate"
                   (click)="startDate.open()" (ngModelChange)="getUserCPCs()">
            <mat-datepicker #startDate=""></mat-datepicker>
            <input class="flex-auto input" [matDatepicker]="endDate" [(ngModel)]="cpcEndDate" [max]="getCPCMaxEndDate()"
                   (click)="endDate.open()" (ngModelChange)="getUserCPCs()">
            <mat-datepicker #endDate=""></mat-datepicker>
          </div>
        </div>
      </section>
      }
      <section class="flex flex-col flex-none p-20 border-b border-solid gap-y-10 border-gray-lighter">
        <button mat-button type="button" class="button is-primary is-inverted" [amplitudeEvent]="'Menu: View Leaderboard'"
                (click)="openLeaderboardModal()"
                appCyData="leaderboardSidenavBtn">
          <span class="flex items-center justify-start w-full">
            <span class="mr-10 mi-18 material-icons">emoji_events</span>
            <span>View leaderboard</span>
          </span>
        </button>
        <button mat-button type="button" class="button is-primary is-inverted" [amplitudeEvent]="'Menu: Contact Support'"
                appCyData="contactSupportBtn"
                (click)="openSupportModal()">
          <span class="flex items-center justify-start w-full">
            <span class="mr-10 mi-18 material-icons">email</span>
            <span>Contact Support</span>
          </span>
        </button>
        <button mat-button type="button" class="button is-primary is-inverted">
          <a [attr.href]="termsOfUseUrl" target="_blank" class="flex items-center justify-start w-full h-full">
            <span class="mr-10 mi-18 material-icons">history_edu</span>
            <span>Terms Of Use</span>
          </a>
        </button>
        @if ((!onBehalfOfUser || user.IsArcUser) && (!user?.UserGroupId && !onBehalfOfUser?.UserGroupId)) {
        <button mat-button type="button" class="button is-primary is-inverted" [amplitudeEvent]="'Menu: Change Site Logo'"
                appCyData="openUserLogoModal"
                (click)="openUploadLogoModal()">
          <span class="flex items-center justify-start w-full">
            <span class="mr-10 mi-18 material-icons">manage_accounts</span>
            <span>Change Site Logo</span>
          </span>
        </button>
        }
        @if (renewals) {
        <button mat-button type="button" class="relative button is-primary is-inverted"
                [amplitudeEvent]="'Menu: Manage Renewals'" [amplitudeEvent]="'Menu: Manage Enrollments'"
                (click)="openEnrollmentModal(renewals, false)">
          @if (userHasOpenRenewals) {
          <span
                class="absolute left-0 w-10 h-10 -ml-4 transform -translate-y-1/2 rounded-full top-1/2 bg-red animate-pulse"
                matTooltip="You have pending enrollment renewals"></span>
          }
          <span class="flex items-center justify-start w-full">
            <span class="mr-10 mi-18 material-icons">date_range</span>
            <span>Manage Enrollments</span>
          </span>
        </button>
        }
        <ng-container>
          <button mat-button type="button" class="relative flex items-center button is-primary is-inverted"
                  [amplitudeEvent]="'Menu: Manage Integrations'" [amplitudeEvent]="'Menu: Manage Integrations'"
                  (click)="openManageIntegrationsModal()"
                  appCyData="manageIntegrations">
            <span class="flex items-center justify-start w-full">
              <span class="mr-10 mi-18 material-icons">settings</span>
              <span>Manage Integrations</span>
              <app-new-feature [expirationDate]="{month:11, day:19, year:2022}" class="ml-5 text-xs"></app-new-feature>
            </span>
          </button>
        </ng-container>
        <button mat-button type="button" class="button is-warning is-inverted" (click)="logout()">
          <span class="flex items-center justify-start w-full">
            <span class="mr-10 mi-18 material-icons">power_settings_new</span>
            <span>Log Out</span>
          </span>
        </button>
      </section>
    </section>
  </mat-sidenav>
  <!-- Portal body -->
  <mat-sidenav-content class="relative flex flex-col h-full overflow-auto portal-body">
    <!-- <app-announcement-banner *ngIf="showBanner">
            <span class="font-bold">Be advised:</span> FIG Portal will undergo planned maintenance on Saturday, April 2nd between 8:30 and 10:30 am ET. Access and/or services may be temporarily unavailable during this time. We apologize for any inconvenience.
          </app-announcement-banner> -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
}
<tour-step-template></tour-step-template>