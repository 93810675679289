import { AgentLicenseOptOutResolver } from '../core/services/resolvers/crd-npn-opt-out-resolver';
import { allOrOneEnum, CustomPortalRoute } from '../shared/models/custom-routes.models';
import { AuthenticationGuard } from '../core/services/guards/authentication-guard/authentication-guard.service';
import { BusinessComponent } from './components/business/business.component';
import { BusinessPermissions, ContractingPermissions, ExplorePermissions } from '../shared/constants/permissions-shorthand.constants';
import { BusinessRouteParamReportTypeEnum } from './components/business/enums/policy-v2.enums';
import { ContractingCompletedResolver } from '../core/services/resolvers/contracting-completed-resolver';
import { CpcReportingListComponent } from './components/business/components/cpc-reporting-list/cpc-reporting-list.component';
import { DevelopmentGuardService } from '../core/services/guards/development-guard/development-guard.service';
import { InforcePolicyListComponent } from './components/business/components/lifecycle-policy-list/inforce-policy-list.component';
import { InformalPolicyDetailsComponent } from './components/business/components/informal-policy-details/informal-policy-details.component';
import { InformalPolicyDetailsV2Component } from './components/business/components/informal-policy-details-v2/informal-policy-details-v2.component';
import { InvestmentPolicyListComponent } from './components/business/components/investment-policy-list/investment-policy-list.component';
import { NgModule } from '@angular/core';
import { PendingPolicyListComponent } from './components/business/components/pending-policy-list/pending-policy-list.component';
import { PermissionGuardService } from '../core/services/guards/permission-guard/permission-guard.service';
import { PolicyDetailsComponent } from './components/business/components/policy-details/policy-details.component';
import { PolicyDetailsV2Component } from './components/business/components/policy-details-v2/policy-details-v2.component';
import { PortalComponent } from './portal.component';
import { RouterModule } from '@angular/router';
import { TermsOfUseCompletedResolver } from '../core/services/resolvers/terms-of-use-resolver';
import { UserMasterAdvisorAgreementRequiredResolver } from '../core/services/resolvers/user-master-advisor-agreement-required-resolver';
import { UserMasterAdvisorAgreementResolver } from '../core/services/resolvers/user-master-advisor-agreement-resolver';

/** Redirects go here */
const newPortalRedirects: CustomPortalRoute[] = [
  {
    path: '',
    redirectTo: 'Dashboard',
    pathMatch: 'full'
  },
  {
    path: 'DashboardV3',
    redirectTo: 'Dashboard',
    pathMatch: 'full'
  },
  {
    path: 'Resources/Details/RegulatoryUpdates',
    redirectTo: 'Explore/Details/RegulatoryUpdates',
    pathMatch: 'full'
  },
  {
    path: 'Business/EApplication/History',
    redirectTo: 'Business/EApplications/History',
    pathMatch: 'full'
  },
  {
    path: 'Business/Fixed/LifecycleV2',
    redirectTo: 'Business/Fixed/Lifecycle',
    pathMatch: 'full'
  },
  {
    path: 'ProductRD/Education/CarePlanning',
    redirectTo: 'Explore/DetailsV2/CarePlanning',
    pathMatch: 'full'
  },
  {
    path: 'ProductRD/Education/Disability',
    redirectTo: 'Explore/DetailsV2/Disability',
    pathMatch: 'full'
  },
  {
    path: 'ProductRD/Education/LifeInsurance',
    redirectTo: 'Explore/DetailsV2/LifeInsurance',
    pathMatch: 'full'
  },
  {
    path: 'ProductRD/Education/Medicare',
    redirectTo: 'Explore/DetailsV2/Medicare',
    pathMatch: 'full'
  },
  {
    path: 'Toolbox/Calculators',
    redirectTo: 'Resources/ToolsCalculators',
    pathMatch: 'full'
  },
  {
    path: 'Resources/Details/TermLifeQuoteEngine',
    redirectTo: 'Resources/Details/TermLifeQuoteEngine',
    pathMatch: 'full'
  },
  {
    path: 'Explore/Details/TermLifeQuoteEngine',
    redirectTo: 'Resources/Details/TermLifeQuoteEngine',
    pathMatch: 'full'
  },
  {
    path: 'Toolbox/ARWTopMYGA',
    redirectTo: 'Resources/Details/ARWTopMYGA',
    pathMatch: 'full'
  },
  {
    path: 'Toolbox/ARWLifetimeIncomeTool',
    redirectTo: 'Resources/Details/ARWLifetimeIncomeTool',
    pathMatch: 'full'
  },
  {
    path: 'ProductRD/Education/FixedIndexedAnnuities',
    redirectTo: 'Resources/FormsGuides',
    pathMatch: 'full'
  },
];

/** Subroutes */
const portalSubRoutesV2: CustomPortalRoute[] = [
  ...newPortalRedirects,
  {
    path: '404',
    loadComponent: () => import('./components/404-not-found/404-not-found.component').then(m => m.NotFoundComponent),
    data: {
      title: '404 Not Found',
    }
  },
  {

    path: 'AngularSandbox',
    loadChildren: () => import('src/app/portal/features/sandbox/sandbox.module').then(m => m.SandboxModule),
    data: {
      title: 'Sandbox',
    },
    canActivate: [DevelopmentGuardService],
  },
  {
    path: 'Dashboard',
    loadChildren: () => import('src/app/portal/features/dashboard-feature/dashboard-feature.module').then(m => m.DashboardFeatureModule),
    data: {
      title: 'Dashboard',
    },
  },
  {
    path: 'OrderComplete',
    loadComponent: () => import('src/app/portal/components/externals/order-complete/order-complete.component').then(m => m.OrderCompleteComponent),
    pathMatch: 'full',
    data: {
      title: 'Order Complete'
    }
  },
  {
    path: 'SignatureComplete',
    loadComponent: () => import('src/app/portal/components/externals/signature-complete/signature-complete.component').then(m => m.SignatureCompleteComponent),
    pathMatch: 'full',
    data: {
      title: 'Signature Complete'
    }
  },
  {
    path: 'Explore',
    loadChildren: () => import('src/app/portal/features/explore-fig/explore-fig.module').then(m => m.ExploreFigModule),
    data: {
      title: 'Explore',
      permissions: ExplorePermissions,
      allOrOne: allOrOneEnum.showIfAll,
    },
    canActivate: [PermissionGuardService],
  },
  {
    path: 'Contracting',
    data: {
      title: 'Contracting',
      permissions: ContractingPermissions,
      allOrOne: allOrOneEnum.showIfOne,
    },
    canActivate: [PermissionGuardService],
    loadChildren: () => import('src/app/portal/features/contracting/contracting-feature.module').then(m => m.ContractingFeatureModule),
  },
  {
    path: 'Business',
    component: BusinessComponent,
    data: {
      title: 'Business',
      permissions: BusinessPermissions.allBusiness,
      allOrOne: allOrOneEnum.showIfOne,
    },
    canActivate: [PermissionGuardService],
    children: [
      {
        path: 'Fixed',
        redirectTo: 'Fixed/Pending',
        pathMatch: 'full',
      },
      {
        path: '',
        redirectTo: 'Fixed/Pending',
        pathMatch: 'full'
      },
      {
        path: 'EApplications',
        loadChildren: () => import('src/app/portal/features/e-application/e-application.module').then(m => m.EApplicationModule),
        canActivate: [PermissionGuardService],
        data: {
          title: 'E-Applications',
          permissions: BusinessPermissions.eApps,
        },
      },
      {
        path: 'Fixed/Pending',
        component: PendingPolicyListComponent,
        data: {
          title: 'Pending',
          reportType: BusinessRouteParamReportTypeEnum.fixed,
        }
      },
      {
        path: 'Fixed/Inforce',
        component: InforcePolicyListComponent,
        data: {
          title: 'Inforce',
          reportType: BusinessRouteParamReportTypeEnum.fixed,
        },
      },
      {
        canActivate: [PermissionGuardService],
        path: 'Fixed/Lifecycle',
        loadChildren: () => import('src/app/portal/features/lifecycle/lifecycle.module').then(m => m.LifecycleModule),
        data: {
          title: 'Lifecycle',
          reportType: BusinessRouteParamReportTypeEnum.lifecycle,
          permissions: BusinessPermissions.lifecycleV2,
          allOrOne: allOrOneEnum.showIfAll,
        },
      },
      {
        path: 'Fixed/CPCReporting',
        component: CpcReportingListComponent,
        data: {
          title: 'CPC Reporting',
          reportType: BusinessRouteParamReportTypeEnum.fixed,
        },
      },
      {
        path: 'Fixed/Informal',
        loadComponent: () => import('src/app/portal/components/business/components/informal-policy-list/informal-policy-list.component').then(m => m.InformalPolicyListComponent),
        data: {
          title: 'Informal',
          reportType: BusinessRouteParamReportTypeEnum.fixed,
        }
      },
      {
        path: 'Wealth',
        component: InvestmentPolicyListComponent,
        data: {
          title: 'Wealth',
          reportType: BusinessRouteParamReportTypeEnum.wealth,
        }
      },
      {
        path: ':reportType/PolicyDetails/:policyGuid',
        redirectTo: ':reportType/AnnuityPolicyDetails/:policyGuid',
        pathMatch: 'full',
      },
      {
        path: ':reportType/AnnuityPolicyDetails/:policyGuid',
        component: PolicyDetailsComponent,
        data: {
          title: 'Policy Details',
          reportType: BusinessRouteParamReportTypeEnum.policyDetails,
        },
      },
      {
        path: ':reportType/PolicyDetailsV2/:policyGuid',
        redirectTo: ':reportType/LifeLtcPolicyDetails/:policyGuid',
        pathMatch: 'full',
      },
      {
        path: ':reportType/LifeLtcPolicyDetails/:policyGuid',
        component: PolicyDetailsV2Component,
        data: {
          title: 'Policy Details',
          reportType: BusinessRouteParamReportTypeEnum.policyDetails,
        },
      },
      {
        path: ':reportType/InformalPolicyDetails/:policyGuid',
        component: InformalPolicyDetailsComponent,
        data: {
          title: 'Informal Policy Details',
          reportType: BusinessRouteParamReportTypeEnum.policyDetails,
        }
      },
      {
        path: ':reportType/InformalPolicyDetailsV2/:policyGuid',
        component: InformalPolicyDetailsV2Component,
        data: {
          title: 'Informal Policy Details',
          reportType: BusinessRouteParamReportTypeEnum.policyDetails
        }
      },
    ],
  },
  {
    path: 'Resources',
    loadChildren: () => import('src/app/portal/features/resources/resources.module').then(m => m.ResourcesModule),
    data: {
      title: 'Resources',
    }
  },
];

/** Main Portal route */
const portalRouteV2: CustomPortalRoute = {
  path: 'Portal',
  component: PortalComponent,
  canActivate: [AuthenticationGuard],
  canActivateChild: [AuthenticationGuard],
  resolve: {
    userMasterAdvisorAgreement: UserMasterAdvisorAgreementResolver,
    userContracting: ContractingCompletedResolver,
    userMasterAdvisorAgreementRequired: UserMasterAdvisorAgreementRequiredResolver,
    userAgentLicensesOptOut: AgentLicenseOptOutResolver,
    userTermsOfUse: TermsOfUseCompletedResolver,
  },
  data: {
    title: 'Portal',
  },
  children: portalSubRoutesV2
};


export const PortalRoutes: CustomPortalRoute[] = [portalRouteV2];

@NgModule({
  imports: [RouterModule.forRoot(PortalRoutes, {
    scrollPositionRestoration: 'enabled', // this is not working due to a bug with mat-sidenav-container interfering with scrolling position. Check portal.component lines 129-133 for workaround
  })],
  exports: [RouterModule],
})
export class PortalRoutingModule { }